import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

function Home() {
  return (
    <div className="wrapper">
      <div className="top-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12">
              <div className="logo">
                <NavLink to="/">
                  <h1>ASM Designs</h1>{" "}
                </NavLink>
                {/* <!-- <img src="./assets/img/logo.jpg" alt="Logo"> --> */}
              </div>
            </div>
            <div className="col-lg-8 col-md-7 d-none d-lg-block">
              <div className="row">
                <div className="col-4">
                  <div className="top-bar-item">
                    <div className="top-bar-icon">
                      <i className="flaticon-calendar"></i>
                    </div>
                    <div className="top-bar-text">
                      <h3>Opening Hour</h3>
                      <p>Mon - Sat, 8:00 - 9:00</p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="top-bar-item">
                    <div className="top-bar-icon">
                      <i className="flaticon-call"></i>
                    </div>
                    <div className="top-bar-text">
                      <h3>Call Us</h3>
                      <p>+91 9199306532</p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="top-bar-item">
                    <div className="top-bar-icon">
                      <i className="flaticon-send-mail"></i>
                    </div>
                    <div className="top-bar-text">
                      <h3>Email Us</h3>
                      <p>bikukumar1986@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Top Bar End --> */}

      {/* <!-- Nav Bar Start --> */}
      <div className="nav-bar">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
            <a href="#" className="navbar-brand">
              MENU
            </a>
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarCollapse"
            >
              <div className="navbar-nav mr-auto">
                <NavLink to="/">
                  <a className="nav-item nav-link active">Home</a>
                </NavLink>
                <NavLink to="/about">
                  <a className="nav-item nav-link">About</a>
                </NavLink>
                <NavLink to="/services">
                  <a className="nav-item nav-link">Service</a>
                </NavLink>
                <NavLink to="/projects">
                  <a className="nav-item nav-link">Project</a>
                </NavLink>
                <NavLink to="/contact">
                  <a className="nav-item nav-link">Contact Us</a>
                </NavLink>

                {/* <div className="nav-item dropdown">
                  <a
                    href="#"
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Pages
                  </a>
                  <div className="dropdown-menu">
                    <a href="blog.html" className="dropdown-item">
                      Blog Page
                    </a>
                    <a href="single.html" className="dropdown-item">
                      Single Page
                    </a>
                  </div>
                </div> */}
                {/* <a href="contact.html" className="nav-item nav-link">
                  Contact
                </a> */}
              </div>
              {/* <div className="ml-auto">
                <NavLink to="/contact">   {" "} <a className="btn" href="contact.html">Contact US</a></NavLink>
              </div> */}
            </div>
          </nav>
        </div>
      </div>
      {/* <!-- Nav Bar End -->

      <!-- Carousel Start --> */}
      <div id="carousel" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#carousel" data-slide-to="0" className="active"></li>
          <li data-target="#carousel" data-slide-to="1"></li>
          <li data-target="#carousel" data-slide-to="2"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src="./assets/port/(1).jpg" alt="Carousel Image" />
            <div className="carousel-caption">
              <p className="animated fadeInRight">We Are Professional Scale Model Makers</p>
              <h1 className="animated fadeInLeft">For Your Dream Project</h1>
              <a
                className="btn animated fadeInUp"
                href="https://wa.me/9199306532"              >
                Get A Quote
              </a>
            </div>
          </div>

          <div className="carousel-item">
            <img src="./assets/port/(2).jpg" alt="Carousel Image" />
            <div className="carousel-caption">
              <p className="animated fadeInRight">We Have Experinced Architects With Us</p>
              <h1 className="animated fadeInLeft">To Design & Understand Your Dream Projects</h1>
              <a
                className="btn animated fadeInUp"
                href="https://wa.me/9199306532"              >
                Get A Quote
              </a>
            </div>
          </div>

          <div className="carousel-item">
            <img src="./assets/port/(3).jpg" alt="Carousel Image" />
            <div className="carousel-caption">
              <p className="animated fadeInRight">We Are Trusted</p>
              <h1 className="animated fadeInLeft">By Many Leading Builders & Architects</h1>
              <a
                className="btn animated fadeInUp"
                href="https://wa.me/9199306532"              >
                Get A Quote
              </a>
            </div>
          </div>
        </div>

        <a
          className="carousel-control-prev"
          href="#carousel"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carousel"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
      {/* <!-- Carousel End -->

      <!-- Feature Start--> */}
      <div className="feature wow fadeInUp" data-wow-delay="0.1s">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12">
              <div className="feature-item">
                <div className="feature-icon">
                  <i className="flaticon-worker"></i>
                </div>
                <div className="feature-text">
                  <h3>Expert Worker</h3>
                  <p>
                  Expert workforce for precise, innovative, and transformative project outcomes. Elevate with us.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="feature-item">
                <div className="feature-icon">
                  <i className="flaticon-building"></i>
                </div>
                <div className="feature-text">
                  <h3>Quality Work</h3>
                  <p>
                  Skilled, dedicated professionals ensuring top-tier quality in every task. Excellence defines our workforce.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="feature-item">
                <div className="feature-icon">
                  <i className="flaticon-call"></i>
                </div>
                <div className="feature-text">
                  <h3>24/7 Support</h3>
                  <p>
                  Reliable assistance around the clock, ensuring seamless support for your needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Feature End-->

      <!-- About Start --> */}
      <div className="about wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <div className="about-img">
                <img src="./assets/img/about.jpg" alt="Image" />
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="section-header text-left">
                {/* <p>LEARN ABOUT US</p> */}
                <h1 class="mb-4 section-title">
                We Are The Architectual Model Making and Design Firm Providing Services Pan India  
                </h1>
              </div>
              <div className="about-text">
                <p>
                ASM DESIGNs, based in Lucknow, is a leading scale model maker
                  company with a 20-year legacy in the industry, known for its
                  outstanding reputation. The Architectural Model Makers team
                  specializes in transforming clients' visions into tangible
                  reality through detailed scale models. From landscape planning
                  and township models to 3D printing and interactive touch scale
                  models, ASM Designs excels in various categories. The
                  15-member team combines the latest tools, laser cutting
                  technology, and human craftsmanship to deliver realistic
                  prototype models that meet client satisfaction. They have
                  expertise in creating models for water treatment plants,
                  flyovers, commercial buildings, cement plants, interiors, and
                  more, catering to clients across India.
                </p>
                <p>
                 
                </p>
                <NavLink to="/about">
                  <a className="btn">Learn More</a>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End -->

      <!-- Fact Start --> */}
      <div className="fact">
        <div className="container-fluid">
          <div className="row counters">
            <div className="col-md-6 fact-left wow slideInLeft">
              <div className="row">
                <div className="col-6">
                  <div className="fact-icon">
                    <i className="flaticon-worker"></i>
                  </div>
                  <div className="fact-text">
                    <h2 data-toggle="counter-up">30</h2>
                    <p>Expert Workers</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="fact-icon">
                    <i className="flaticon-building"></i>
                  </div>
                  <div className="fact-text">
                    <h2 data-toggle="counter-up">65</h2>
                    <p>Happy Clients</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 fact-right wow slideInRight">
              <div className="row">
                <div className="col-6">
                  <div className="fact-icon">
                    <i className="flaticon-address"></i>
                  </div>
                  <div className="fact-text">
                    <h2 data-toggle="counter-up">120</h2>
                    <p>Completed Projects</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="fact-icon">
                    <i className="flaticon-crane"></i>
                  </div>
                  <div className="fact-text">
                    <h2 data-toggle="counter-up">25</h2>
                    <p>Running Projects</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Fact End -->

      <!-- Service Start --> */}
      <div className="service">
        <div className="container">
          <div className="section-header text-center">
            {/* <p>OUR AWESOME SERVICES</p> */}
            <h2>We Provide Services In</h2>
          </div>
          <div className="row">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item">
                <div className="service-img">
                  <img
                    src="./assets/img/service-1.jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="service-overlay">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Phasellus nec pretium mi. Curabitur facilisis ornare velit
                      non vulputate. Aliquam metus tortor, auctor id gravida
                      condimentum, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="service-text">
                  <h3>Architectural Model Making</h3>
                  <a
                    className="btn"
                    href="./assets/img/service-1.jpg"
                    data-lightbox="service"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="service-item">
                <div className="service-img">
                  <img
                    src="./assets/img/service-2.jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="service-overlay">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Phasellus nec pretium mi. Curabitur facilisis ornare velit
                      non vulputate. Aliquam metus tortor, auctor id gravida
                      condimentum, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="service-text">
                  <h3>Model Renovation</h3>
                  <a
                    className="btn"
                    href="./assets/img/service-2.jpg"
                    data-lightbox="service"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item">
                <div className="service-img">
                  <img
                    src="./assets/img/service-3.jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="service-overlay">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Phasellus nec pretium mi. Curabitur facilisis ornare velit
                      non vulputate. Aliquam metus tortor, auctor id gravida
                      condimentum, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="service-text">
                  <h3>Architectural Designing</h3>
                  <a
                    className="btn"
                    href="./assets/img/service-3.jpg"
                    data-lightbox="service"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="service-item">
                <div className="service-img">
                  <img
                    src="./assets/img/service-4.jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="service-overlay">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Phasellus nec pretium mi. Curabitur facilisis ornare velit
                      non vulputate. Aliquam metus tortor, auctor id gravida
                      condimentum, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="service-text">
                  <h3>Interior Designing and model making</h3>
                  <a
                    className="btn"
                    href="./assets/img/service-4.jpg"
                    data-lightbox="service"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item">
                <div className="service-img">
                  <img
                    src="./assets/img/service-5.jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="service-overlay">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Phasellus nec pretium mi. Curabitur facilisis ornare velit
                      non vulputate. Aliquam metus tortor, auctor id gravida
                      condimentum, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="service-text">
                  <h3>3D Modelling & Walkthrough</h3>
                  <a
                    className="btn"
                    href="./assets/img/service-5.jpg"
                    data-lightbox="service"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.6s"
            >
              <div className="service-item">
                <div className="service-img">
                  <img
                    src="./assets/img/service-6.jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="service-overlay">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Phasellus nec pretium mi. Curabitur facilisis ornare velit
                      non vulputate. Aliquam metus tortor, auctor id gravida
                      condimentum, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="service-text">
                  <h3>Township Planning & Model Making</h3>
                  <a
                    className="btn"
                    href="./assets/img/service-6.jpg"
                    data-lightbox="service"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Service End --> */}
      {/* why choose Us */}
      <div class="container-fluid bg-light">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 mt-5 py-5 pr-lg-5">
              <h6 class="text-primary font-weight-normal text-uppercase mb-3">
                Why Choose Us?
              </h6>
              <h1 class="mb-4 section-title">
              20+ Years Of Expertise In The Architectual Model Making Industry              </h1>
              <p class="mb-4">
                We believe that each clients has distinct needs and preferences.
                To help you do this, we've gathered and most innovative designs
                to make your dream become reality. Having a plethora of ideas
                that can dazzle you at every turn. You are always welcomed to
                discuss the ideas.{" "}
              </p>
              <ul class="list-inline">
                <li>
                  <h5>
                    <i class="far fa-check-square text-primary mr-3"></i>20+
                    Years Experience
                  </h5>
                </li>
                <li>
                  <h5>
                    <i class="far fa-check-square text-primary mr-3"></i>Best
                    Best Architectual Model Making & Design
                  </h5>
                </li>
                <li>
                  <h5>
                    <i class="far fa-check-square text-primary mr-3"></i>
                    Customer Satisfaction
                  </h5>
                </li>
                <li>
                  <h5>
                    <i class="far fa-check-square text-primary mr-3"></i>
                    Meticulous Details
                  </h5>
                </li>
              </ul>
              {/* <a href="" class="btn btn-primary mt-3 py-2 px-4">
                View More
              </a> */}
            </div>
            <div class="col-lg-5">
              <div class="d-flex flex-column align-items-center justify-content-center h-100 overflow-hidden">
                <img class="h-100" src="./assets/img/service-6.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Video Start -->  */}
      <div className="video wow fadeIn" data-wow-delay="0.1s">
        <div className="container">
          <button
            type="button"
            className="btn-play"
            data-toggle="modal"
            // https://www.youtube.com/watch?v=0RtsoDZZr3Q
            data-src="https://www.youtube.com/embed/0RtsoDZZr3Q"
            data-target="#videoModal"
          >
            <span></span>
          </button>
        </div>
      </div>

      <div
        className="modal fade"
        id="videoModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {/* <!-- 16:9 aspect ratio --> */}
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src=""
                  id="video"
                  allowscriptaccess="always"
                  allow="autoplay"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Video End -->

      <!-- Team Start --> */}
      <div className="team">
        <div className="container">
          <div className="section-header text-center">
            <p>Our Team</p>
            <h2>Meet Our Engineer</h2>
          </div>
          <div className="row">
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="team-img">
                  <img
                    src="./assets/img/team-1.jpg"
                    alt="Team Image"
                    style={{
                      width: "100%",
                      height: "350px",
                      objectFit: "cover",
                    }}
                  />{" "}
                </div>
                <div className="team-text">
                  <h2>Biku Kumar</h2>
                  <p>Director</p>
                </div>
                <div className="team-social">
                   {/* <a className="social-tw" href="">
                    <i className="fab fa-twitter"></i>
                  </a> */}
                  <a className="social-fb" href="">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                   {/* <a className="social-li" href="">
                    <i className="fab fa-linkedin-in"></i>
                  </a> */}
                  <a className="social-in" href="">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="team-item">
                <div className="team-img">
                  <img
                    src="./assets/img/team-2.jpg"
                    alt="Team Image"
                    style={{
                      width: "100%",
                      height: "350px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="team-text">
                  <h2>Vikash Kumar</h2>
                  <p>Junior Model Maker</p>
                </div>
                <div className="team-social">
                   {/* <a className="social-tw" href="">
                    <i className="fab fa-twitter"></i>
                  </a> */}
                  <a className="social-fb" href="">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                   {/* <a className="social-li" href="">
                    <i className="fab fa-linkedin-in"></i>
                  </a> */}
                  <a className="social-in" href="">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="team-item">
                <div className="team-img">
                  <img
                    src="./assets/img/team-3.jpg"
                    alt="Team Image"
                    style={{
                      width: "100%",
                      height: "350px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="team-text">
                  <h2>Nishant Kumar</h2>
                  <p>Senior Architect</p>
                </div>
                <div className="team-social">
                   {/* <a className="social-tw" href="">
                    <i className="fab fa-twitter"></i>
                  </a> */}
                  <a className="social-fb" href="">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                   {/* <a className="social-li" href="">
                    <i className="fab fa-linkedin-in"></i>
                  </a> */}
                  <a className="social-in" href="">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.4s"            >
              <div className="team-item">
                <div className="team-img">
                  <img
                    src="./assets/img/team-4.jpg"
                    alt="Team Image"
                    style={{
                      width: "100%",
                      height: "350px",
                      objectFit: "cover",
                    }}
                  />{" "}
                </div>
                <div className="team-text">
                  <h2>Jyoti Chauhan</h2>
                  <p>Associate Architect</p>
                </div>
                <div className="team-social">
                   {/* <a className="social-tw" href="">
                    <i className="fab fa-twitter"></i>
                  </a> */}
                  <a className="social-fb" href="">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                   {/* <a className="social-li" href="">
                    <i className="fab fa-linkedin-in"></i>
                  </a> */}
                  <a className="social-in" href="">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="team-item">
                <div className="team-img">
                  <img
                    src="./assets/img/team-5.jpg"
                    alt="Team Image"
                    style={{
                      width: "100%",
                      height: "350px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="team-text">
                  <h2>Mahendra Kumar</h2>
                  <p>Junior Model Maker</p>
                </div>
                <div className="team-social">
                   {/* <a className="social-tw" href="">
                    <i className="fab fa-twitter"></i>
                  </a> */} 
                  <a className="social-fb" href="">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                    {/* <a className="social-li" href="">
                    <i className="fab fa-linkedin-in"></i>
                  </a> */} 
                  <a className="social-in" href="">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Team End --> */}

      {/* <!-- FAQs Start -->  */}
      <div className="faqs">
        <div className="container">
          <div className="section-header text-center">
            <p>Frequently Asked Question</p>
            <h2>You May Ask</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div id="accordion-1">
                <div className="card wow fadeInLeft" data-wow-delay="0.1s">
                  <div className="card-header">
                    <a
                      className="card-link collapsed"
                      data-toggle="collapse"
                      href="#collapseOne"
                    >
                      What types of architectural models do you create?
                    </a>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse"
                    data-parent="#accordion-1"
                  >
                    <div className="card-body">
                      We specialize in creating a wide range of architectural
                      models, including residential, commercial, industrial, and
                      landscape models. Our team is skilled in crafting detailed
                      scale models to meet various project requirements.
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInLeft" data-wow-delay="0.2s">
                  <div className="card-header">
                    <a
                      className="card-link collapsed"
                      data-toggle="collapse"
                      href="#collapseTwo"
                    >
                      How long does it take to complete an model?
                    </a>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    data-parent="#accordion-1"
                  >
                    <div className="card-body">
                      The timeline for completing a model depends on factors
                      such as complexity, size, and specific client
                      requirements. Generally, our team strives to deliver
                      high-quality models within a reasonable timeframe, and
                      project timelines will be discussed during the
                      consultation phase.
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInLeft" data-wow-delay="0.3s">
                  <div className="card-header">
                    <a
                      className="card-link collapsed"
                      data-toggle="collapse"
                      href="#collapseThree"
                    >
                      What materials do you use for your architectural models?
                    </a>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    data-parent="#accordion-1"
                  >
                    <div className="card-body">
                      We use a variety of high-quality materials, including
                      wood, acrylic, foam, and other industry-standard
                      model-making materials. The choice of materials depends on
                      the project requirements and the desired aesthetic.
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInLeft" data-wow-delay="0.4s">
                  <div className="card-header">
                    <a
                      className="card-link collapsed"
                      data-toggle="collapse"
                      href="#collapseFour"
                    >
                      Can you handle large-scale or complex projects?
                    </a>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    data-parent="#accordion-1"
                  >
                    <div className="card-body">
                      Yes, we have experience working on a diverse range of
                      projects, from small-scale residential models to
                      large-scale commercial and urban planning models. Our team
                      is equipped to handle complex and challenging projects
                      with precision and expertise.
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInLeft" data-wow-delay="0.5s">
                  <div className="card-header">
                    <a
                      className="card-link collapsed"
                      data-toggle="collapse"
                      href="#collapseFive"
                    >
                      How do I request a quote?
                    </a>
                  </div>
                  <div
                    id="collapseFive"
                    className="collapse"
                    data-parent="#accordion-1"
                  >
                    <div className="card-body">
                      You can request a quote by reaching out to us via email or
                      phone. Please provide details about your project,
                      including design plans, dimensions, and any specific
                      requirements, to help us generate an accurate quote for
                      your custom model.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div id="accordion-2">
                <div className="card wow fadeInRight" data-wow-delay="0.1s">
                  <div className="card-header">
                    <a
                      className="card-link collapsed"
                      data-toggle="collapse"
                      href="#collapseSix"
                    >
                      Can I make changes to my model after it's started?
                    </a>
                  </div>
                  <div
                    id="collapseSix"
                    className="collapse"
                    data-parent="#accordion-2"
                  >
                    <div className="card-body">
                      We understand that design changes may occur during the
                      course of a project. Depending on the stage of production,
                      we'll work with you to accommodate reasonable changes,
                      although additional costs or production time may be
                      involved.
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInRight" data-wow-delay="0.2s">
                  <div className="card-header">
                    <a
                      className="card-link collapsed"
                      data-toggle="collapse"
                      href="#collapseSeven"
                    >
                      What sets your company apart from others?
                    </a>
                  </div>
                  <div
                    id="collapseSeven"
                    className="collapse"
                    data-parent="#accordion-2"
                  >
                    <div className="card-body">
                      Our company is distinguished by its commitment to
                      precision, attention to detail, and collaborative approach
                      with clients. We prioritize quality craftsmanship and
                      strive to exceed our clients' expectations, ensuring that
                      each architectural model reflects the uniqueness of the
                      design it represents.
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInRight" data-wow-delay="0.3s">
                  <div className="card-header">
                    <a
                      className="card-link collapsed"
                      data-toggle="collapse"
                      href="#collapseEight"
                    >
                      Do you offer rush services for tight deadlines?{" "}
                    </a>
                  </div>
                  <div
                    id="collapseEight"
                    className="collapse"
                    data-parent="#accordion-2"
                  >
                    <div className="card-body">
                      Yes, we provide rush services for projects with tight
                      deadlines. Contact us, and we'll discuss options to
                      accommodate your specific timeline.
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInRight" data-wow-delay="0.4s">
                  <div className="card-header">
                    <a
                      className="card-link collapsed"
                      data-toggle="collapse"
                      href="#collapseNine"
                    >
                      Are your models suitable for educational purposes?
                    </a>
                  </div>
                  <div
                    id="collapseNine"
                    className="collapse"
                    data-parent="#accordion-2"
                  >
                    <div className="card-body">
                      Absolutely! Our models are often used for educational
                      purposes, providing a tangible and visually engaging way
                      to explore architectural concepts.
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInRight" data-wow-delay="0.5s">
                  <div className="card-header">
                    <a
                      className="card-link collapsed"
                      data-toggle="collapse"
                      href="#collapseTen"
                    >
                      What maintenance is required for your architectural
                      models?{" "}
                    </a>
                  </div>
                  <div
                    id="collapseTen"
                    className="collapse"
                    data-parent="#accordion-2"
                  >
                    <div className="card-body">
                      Our models are crafted with durability in mind, requiring
                      minimal maintenance. Regular dusting and gentle handling
                      are usually sufficient to keep them in excellent
                      condition.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- FAQs End -->

    
      <!-- Footer Start --> */}

      <div class="whatsapp-button">
        <a
          href="https://wa.me/9199306532"
          target="_blank"
          title="Chat with us on WhatsApp"
        >
          <img src="./assets/asm/22.png" alt="WhatsApp Icon" />
        </a>
      </div>

      <div className="footer wow fadeIn" data-wow-delay="0.3s">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3">
            <div className="footer-contact">
                <h2>Office Contact</h2>
                <p>
                  <i className="fa fa-map-marker-alt"></i>Gomti Nagar, Lucknow, Uttar Pradesh
                </p>
                <p>
                  <i className="fa fa-map-marker-alt"></i>Saraiya Ganj, Tower Chock, Behind, Khadim's Showroom, Muzaffarpur, BIHAR
                </p>
                <p>
                  <i className="fa fa-phone-alt"></i>+91 9199306532
                </p>
                <p>
                  <i className="fa fa-envelope"></i>bikukumar1986@gmail.com
                </p>
                <div className="footer-social">
                  {/* <a href="https://freewebsitecode.com/">
                    <i className="fab fa-twitter"></i>
                  </a> */}
                  <a href="https://www.facebook.com/profile.php?id=100084716586443">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  {/* <a href="https://freewebsitecode.com/">
                    <i className="fab fa-linkedin-in"></i>
                  </a> */}
                  <a href="https://www.instagram.com/architectural_modelmaker_biku/?igsh=MThhbXk0ZW5nN2Ntag%3D%3D">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="https://youtube.com/channel/UCQhFSIullboRQFuSfPgJ_kw">
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="footer-link">
                <h2>Services Areas</h2>
                <a>Architectural Model Making</a>
                <a>Model Renovation</a>
                <a>Architectural Designing</a>
                <a>Interior Designing and model making</a>
                <a>3D Modelling & Walkthrough</a>
                <a>Township Planning & Model Making</a>
              </div>
            </div>
            {/* <div className="col-md-6 col-lg-3">
              <div className="footer-link">
                <h2>Useful Pages</h2>
                <a href="">About Us</a>
                <a href="">Contact Us</a>
                <a href="">Our Team</a>
                <a href="">Projects</a>
                <a href="">Testimonial</a>
              </div>
            </div> */}
            <div className="col-md-6 col-lg-6">
              <div className="newsletter">
                <h2>Newsletter</h2>
                <p>
                  Stay inspired with our monthly newsletter, where creativity
                  meets architecture. Join us on a journey of design excellence!
                </p>
                <div className="form">
                  <input className="form-control" placeholder="Email here" />
                  <button className="btn">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container footer-menu">
          <div className="f-menu">
            <NavLink to="/contact">
              <a>Contact Us</a>
            </NavLink>
            <NavLink to="/about">
              <a>About Us</a>
            </NavLink>

            {/* 
            <a href="">Terms of use</a>
            <a href="">Privacy policy</a>
            <a href="">Cookies</a>
            <a href="">Help</a>
            <a href="">FQAs</a> */}
          </div>
        </div>
        <div className="container copyright">
          <div className="row">
            <div className="col-md-6">
              <p>
                &copy; <a>Asm Designs</a>, All Right Reserved.
              </p>
            </div>

            {/* <div className="col-md-6">
              <p>
                Designed By
                <a href="https://freewebsitecode.com">Free Website Code</a>
              </p>
            </div> */}
          </div>
        </div>
      </div>

      <a href="#" className="back-to-top">
        <i className="fa fa-chevron-up"></i>
      </a>
    </div>
  );
}

export default Home;

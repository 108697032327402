import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Projects from './components/Projects';
import Services from './components/Services';


const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return null;
};

function App() {
  return (
  <BrowserRouter>
  <ScrollToTop />
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/projects' element={<Projects/>}/>
      <Route path='/services' element={<Services/>}/>
    </Routes>
  </BrowserRouter>
  );
}

export default App;

import React from "react";
import { NavLink } from "react-router-dom";

function Contact() {
  return (
    <div className="wrapper">
      {/* <!-- Top Bar Start --> */}
      <div className="top-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12">
              <div className="logo">
                <NavLink to="/">
                  <h1>ASM Designs</h1>{" "}
                </NavLink>
                {/* <!-- <img src="./assets/img/logo.jpg" alt="Logo"> --> */}
              </div>
            </div>
            <div className="col-lg-8 col-md-7 d-none d-lg-block">
              <div className="row">
                <div className="col-4">
                  <div className="top-bar-item">
                    <div className="top-bar-icon">
                      <i className="flaticon-calendar"></i>
                    </div>
                    <div className="top-bar-text">
                      <h3>Opening Hour</h3>
                      <p>Mon - Sat, 8:00 - 9:00</p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="top-bar-item">
                    <div className="top-bar-icon">
                      <i className="flaticon-call"></i>
                    </div>
                    <div className="top-bar-text">
                      <h3>Call Us</h3>
                      <p>+91 9199306532</p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="top-bar-item">
                    <div className="top-bar-icon">
                      <i className="flaticon-send-mail"></i>
                    </div>
                    <div className="top-bar-text">
                      <h3>Email Us</h3>
                      <p>bikukumar1986@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Top Bar End -->

    <!-- Nav Bar Start --> */}
      <div className="nav-bar">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
            <a href="#" className="navbar-brand">
              MENU
            </a>
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarCollapse"
            >
              <div className="navbar-nav mr-auto">
                <NavLink to="/">
                  <a className="nav-item nav-link active">Home</a>
                </NavLink>
                <NavLink to="/about">
                  <a className="nav-item nav-link">About</a>
                </NavLink>
                <NavLink to="/services">
                  <a className="nav-item nav-link">Service</a>
                </NavLink>
                <NavLink to="/projects">
                  <a className="nav-item nav-link">Project</a>
                </NavLink>
                <NavLink to="/contact">
                  <a className="nav-item nav-link">Contact Us</a>
                </NavLink>

                {/* <div className="nav-item dropdown">
                  <a
                    href="#"
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Pages
                  </a>
                  <div className="dropdown-menu">
                    <a href="blog.html" className="dropdown-item">
                      Blog Page
                    </a>
                    <a href="single.html" className="dropdown-item">
                      Single Page
                    </a>
                  </div>
                </div> */}
                {/* <a href="contact.html" className="nav-item nav-link">
                  Contact
                </a> */}
              </div>
              {/* <div className="ml-auto">
                <NavLink to="/contact">   {" "} <a className="btn" href="contact.html">Contact US</a></NavLink>
              </div> */}
            </div>
          </nav>
        </div>
      </div>
      {/* <!-- Nav Bar End -->

    <!-- Page Header Start --> */}
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Contact Us</h2>
            </div>
            <div className="col-12">
              <a href="">Home</a>
              <a href="">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Header End -->

    <!-- Contact Start --> */}
      <div className="contact wow fadeInUp">
        <div className="container">
          <div className="section-header text-center">
            <h2>Get In Touch</h2>
            {/* <h2>For Any Query</h2> */}
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="contact-info">
                <div className="contact-item">
                  <i className="flaticon-address"></i>
                  <div className="contact-text">
                    <h2>Address 1</h2>
                    <p>4/91 Vipul Khand, Gomti Nagar, Lucknow, Uttar Pradesh</p>
                  </div>
                </div>
                <div className="contact-item">
                  <i className="flaticon-address"></i>
                  <div className="contact-text">
                    <h2>Address 2</h2>
                    <p>Saraiya Ganj, Tower Chock, Behind, Khadim's Showroom, Muzaffarpur, BIHAR</p>
                  </div>
                </div>
                <div className="contact-item">
                  <i className="flaticon-call"></i>
                  <div className="contact-text">
                    <h2>Phone</h2>
                    <p>+91 9199306532</p>
                  </div>
                </div>
                <div className="contact-item">
                  <i className="flaticon-send-mail"></i>
                  <div className="contact-text">
                    <h2>Email</h2>
                    <p>bikukumar1986@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-form">
                <div id="success"></div>
                <form
                  name="sentMessage"
                  id="contactForm"
                  novalidate="novalidate"
                >
                  <div className="control-group">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      required="required"
                      data-validation-required-message="Please enter your name"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="control-group">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Your Email"
                      required="required"
                      data-validation-required-message="Please enter your email"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="control-group">
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      placeholder="Subject"
                      required="required"
                      data-validation-required-message="Please enter a subject"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="control-group">
                    <textarea
                      className="form-control"
                      id="message"
                      placeholder="Message"
                      required="required"
                      data-validation-required-message="Please enter your message"
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div>
                    <button
                      className="btn"
                      type="submit"
                      id="sendMessageButton"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Contact End -->

    <!-- Footer Start --> */}
      <div class="whatsapp-button">
        <a
          href="https://wa.me/9199306532"
          target="_blank"
          title="Chat with us on WhatsApp"
        >
          <img src="./assets/asm/22.png" alt="WhatsApp Icon" />
        </a>
      </div>

      <div className="footer wow fadeIn" data-wow-delay="0.3s">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3">
            <div className="footer-contact">
                <h2>Office Contact</h2>
                <p>
                  <i className="fa fa-map-marker-alt"></i>Gomti Nagar, Lucknow, Uttar Pradesh
                </p>
                <p>
                  <i className="fa fa-map-marker-alt"></i>Saraiya Ganj, Tower Chock, Behind, Khadim's Showroom, Muzaffarpur, BIHAR
                </p>
                <p>
                  <i className="fa fa-phone-alt"></i>+91 9199306532
                </p>
                <p>
                  <i className="fa fa-envelope"></i>bikukumar1986@gmail.com
                </p>
                <div className="footer-social">
                  {/* <a href="https://freewebsitecode.com/">
                    <i className="fab fa-twitter"></i>
                  </a> */}
                  <a href="https://www.facebook.com/profile.php?id=100084716586443">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  {/* <a href="https://freewebsitecode.com/">
                    <i className="fab fa-linkedin-in"></i>
                  </a> */}
                  <a href="https://www.instagram.com/architectural_modelmaker_biku/?igsh=MThhbXk0ZW5nN2Ntag%3D%3D">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="https://youtube.com/channel/UCQhFSIullboRQFuSfPgJ_kw">
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="footer-link">
                <h2>Services Areas</h2>
                <a>Architectural Model Making</a>
                <a>Model Renovation</a>
                <a>Architectural Designing</a>
                <a>Interior Designing and model making</a>
                <a>3D Modelling & Walkthrough</a>
                <a>Township Planning & Model Making</a>
              </div>
            </div>
            {/* <div className="col-md-6 col-lg-3">
              <div className="footer-link">
                <h2>Useful Pages</h2>
                <a href="">About Us</a>
                <a href="">Contact Us</a>
                <a href="">Our Team</a>
                <a href="">Projects</a>
                <a href="">Testimonial</a>
              </div>
            </div> */}
            <div className="col-md-6 col-lg-6">
              <div className="newsletter">
                <h2>Newsletter</h2>
                <p>
                  Stay inspired with our monthly newsletter, where creativity
                  meets architecture. Join us on a journey of design excellence!
                </p>
                <div className="form">
                  <input className="form-control" placeholder="Email here" />
                  <button className="btn">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container footer-menu">
          <div className="f-menu">
            <NavLink to="/contact">
              <a>Contact Us</a>
            </NavLink>
            <NavLink to="/about">
              <a>About Us</a>
            </NavLink>

            {/* 
            <a href="">Terms of use</a>
            <a href="">Privacy policy</a>
            <a href="">Cookies</a>
            <a href="">Help</a>
            <a href="">FQAs</a> */}
          </div>
        </div>
        <div className="container copyright">
          <div className="row">
            <div className="col-md-6">
              <p>
                &copy; <a>Asm Designs</a>, All Right Reserved.
              </p>
            </div>

            {/* <div className="col-md-6">
              <p>
                Designed By
                <a href="https://freewebsitecode.com">Free Website Code</a>
              </p>
            </div> */}
          </div>
        </div>
      </div>

      <a href="#" className="back-to-top">
        <i className="fa fa-chevron-up"></i>
      </a>
    </div>
  );
}

export default Contact;

import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

function Projects() {
  return (
    <div className="wrapper">
      {/* <!-- Top Bar Start --> */}
      <div className="top-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12">
              <div className="logo">
                <NavLink to="/">
                  <h1>ASM Designs</h1>{" "}
                </NavLink>
                {/* <!-- <img src="./assets/img/logo.jpg" alt="Logo"> --> */}
              </div>
            </div>
            <div className="col-lg-8 col-md-7 d-none d-lg-block">
              <div className="row">
                <div className="col-4">
                  <div className="top-bar-item">
                    <div className="top-bar-icon">
                      <i className="flaticon-calendar"></i>
                    </div>
                    <div className="top-bar-text">
                      <h3>Opening Hour</h3>
                      <p>Mon - Sat, 8:00 - 9:00</p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="top-bar-item">
                    <div className="top-bar-icon">
                      <i className="flaticon-call"></i>
                    </div>
                    <div className="top-bar-text">
                      <h3>Call Us</h3>
                      <p>+91 9199306532</p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="top-bar-item">
                    <div className="top-bar-icon">
                      <i className="flaticon-send-mail"></i>
                    </div>
                    <div className="top-bar-text">
                      <h3>Email Us</h3>
                      <p>bikukumar1986@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Top Bar End -->

    <!-- Nav Bar Start --> */}
      <div className="nav-bar">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
            <a href="#" className="navbar-brand">
              MENU
            </a>
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarCollapse"
            >
              <div className="navbar-nav mr-auto">
                <NavLink to="/">
                  <a className="nav-item nav-link active">Home</a>
                </NavLink>
                <NavLink to="/about">
                  <a className="nav-item nav-link">About</a>
                </NavLink>
                <NavLink to="/services">
                  <a className="nav-item nav-link">Service</a>
                </NavLink>
                <NavLink to="/projects">
                  <a className="nav-item nav-link">Project</a>
                </NavLink>
                <NavLink to="/contact">
                  <a className="nav-item nav-link">Contact Us</a>
                </NavLink>

                {/* <div className="nav-item dropdown">
                  <a
                    href="#"
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Pages
                  </a>
                  <div className="dropdown-menu">
                    <a href="blog.html" className="dropdown-item">
                      Blog Page
                    </a>
                    <a href="single.html" className="dropdown-item">
                      Single Page
                    </a>
                  </div>
                </div> */}
                {/* <a href="contact.html" className="nav-item nav-link">
                  Contact
                </a> */}
              </div>
              {/* <div className="ml-auto">
                <NavLink to="/contact">   {" "} <a className="btn" href="contact.html">Contact US</a></NavLink>
              </div> */}
            </div>
          </nav>
        </div>
      </div>
      {/* <!-- Nav Bar End -->
    
    
    <!-- Page Header Start --> */}
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Our Projects</h2>
            </div>
            <div className="col-12">
              <a href="">Home</a>
              <a href="">Our Projects</a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Header End -->


    <!-- Portfolio Start --> */}
      <div className="portfolio">
        <div className="container">
          <div className="section-header text-center">
            {/* <p>Our Projects</p> */}
            {/* <h2>Our Projects</h2> */}
          </div>
          <div className="row">
            {/* <div className="col-12">
              <ul id="portfolio-flters">
                <li data-filter="*" className="filter-active">
                  All
                </li>
                <li data-filter=".first">Complete</li>
                <li data-filter=".second">Running</li>
                <li data-filter=".third">Upcoming</li>
              </ul>
            </div> */}
          </div>
          <div className="row portfolio-container">
            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/a.jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/a.jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item second wow fadeInUp"
              data-wow-delay="0.2s"          >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/b.jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/b.jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>


            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item second wow fadeInUp"
              data-wow-delay="0.2s"          >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(1).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(1).jpg.jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item second wow fadeInUp"
              data-wow-delay="0.2s"          >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(2).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(2).jpg.jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(3).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(3).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item first wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(4).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(4).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item second wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(5).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(5).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          
          
            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(8).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(8).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(9).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(9).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(10).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(10).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(11).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(11).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(12).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(12).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(13).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(13).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(14).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(14).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(15).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(15).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(16).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(16).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(17).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(17).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(18).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(18).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(19).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(19).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(20).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                   {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(20).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(21).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(21).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(22).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(22).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(23).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(23).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>


            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(24).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(24).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(25).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(25).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(26).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(26).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(27).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(27).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(28).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(28).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(29).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(29).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(30).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(30).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(31).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(31).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(32).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(32).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(33).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(33).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(34).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(34).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(35).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(35).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(36).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(36).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(37).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(37).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>


            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(38).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(38).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>


            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(39).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(39).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>


            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(40).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(40).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>


            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(41).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(41).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(42).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(42).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>


            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(43).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(43).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(44).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(44).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(45).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(45).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>


            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(46).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(46).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(47).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(47).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>


            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(48).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(48).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>


            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(49).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(49).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(50).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(50).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(51).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(51).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(52).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(52).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(53).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(53).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(54).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(54).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(55).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(55).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(56).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(56).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(57).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(57).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(58).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(58).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(59).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(59).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>


            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(60).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(59).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(61).jpeg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(61).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(62).jpeg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(62).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(63).jpeg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(63).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(64).jpeg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(64).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>





            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(65).jpeg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(65).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(66).jpeg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(66).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(67).jpeg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(67).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>





            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(68).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(69).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(70).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(71).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>






            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(72).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(73).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(74).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(75).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(76).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(77).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(78).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(79).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(80).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(81).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item third wow fadeInUp"
              data-wow-delay="0.3s"            >
              <div className="portfolio-warp">
                <div className="portfolio-img">
                  <img
                    src="./assets/asm/(82).jpg"
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="portfolio-overlay">
                    <p>
                      Lorem ipsum dolor sit amet elit. Phasel nec pretium mi.
                      Curabit facilis ornare velit non. Aliqu metus tortor,
                      auctor id gravi condime, viverra quis sem.
                    </p>
                  </div> */}
                </div>
                <div className="portfolio-text">
                  {/* <h3>Project Name</h3> */}
                  <a
                    className="btn"
                    href="./assets/asm/(83).jpg"
                    data-lightbox="portfolio"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ fontSize: "26px" }}
                    />
                  </a>
                </div>
              </div>
            </div>    

          </div>

          {/* <div className="row">
            <div className="col-12 load-more">
              <a className="btn" href="#">
                Load More
              </a>
            </div>
          </div> */}
        </div>
      </div>
      {/* <!-- Portfolio End -->


    <!-- Footer Start --> */}

      <div class="whatsapp-button">
        <a
          href="https://wa.me/9199306532"
          target="_blank"
          title="Chat with us on WhatsApp"
        >
          <img src="./assets/asm/22.png" alt="WhatsApp Icon" />
        </a>
      </div>

      <div className="footer wow fadeIn" data-wow-delay="0.3s">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3">
            <div className="footer-contact">
                <h2>Office Contact</h2>
                <p>
                  <i className="fa fa-map-marker-alt"></i>Gomti Nagar, Lucknow, Uttar Pradesh
                </p>
                <p>
                  <i className="fa fa-map-marker-alt"></i>Saraiya Ganj, Tower Chock, Behind, Khadim's Showroom, Muzaffarpur, BIHAR
                </p>
                <p>
                  <i className="fa fa-phone-alt"></i>+91 9199306532
                </p>
                <p>
                  <i className="fa fa-envelope"></i>bikukumar1986@gmail.com
                </p>
                <div className="footer-social">
                  {/* <a href="https://freewebsitecode.com/">
                    <i className="fab fa-twitter"></i>
                  </a> */}
                  <a href="https://www.facebook.com/profile.php?id=100084716586443">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  {/* <a href="https://freewebsitecode.com/">
                    <i className="fab fa-linkedin-in"></i>
                  </a> */}
                  <a href="https://www.instagram.com/architectural_modelmaker_biku/?igsh=MThhbXk0ZW5nN2Ntag%3D%3D">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="https://youtube.com/channel/UCQhFSIullboRQFuSfPgJ_kw">
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="footer-link">
                <h2>Services Areas</h2>
                <a>Architectural Model Making</a>
                <a>Model Renovation</a>
                <a>Architectural Designing</a>
                <a>Interior Designing and model making</a>
                <a>3D Modelling & Walkthrough</a>
                <a>Township Planning & Model Making</a>
              </div>
            </div>
            {/* <div className="col-md-6 col-lg-3">
              <div className="footer-link">
                <h2>Useful Pages</h2>
                <a href="">About Us</a>
                <a href="">Contact Us</a>
                <a href="">Our Team</a>
                <a href="">Projects</a>
                <a href="">Testimonial</a>
              </div>
            </div> */}
            <div className="col-md-6 col-lg-6">
              <div className="newsletter">
                <h2>Newsletter</h2>
                <p>
                  Stay inspired with our monthly newsletter, where creativity
                  meets architecture. Join us on a journey of design excellence!
                </p>
                <div className="form">
                  <input className="form-control" placeholder="Email here" />
                  <button className="btn">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container footer-menu">
          <div className="f-menu">
            <NavLink to="/contact">
              <a>Contact Us</a>
            </NavLink>
            <NavLink to="/about">
              <a>About Us</a>
            </NavLink>

            {/* 
            <a href="">Terms of use</a>
            <a href="">Privacy policy</a>
            <a href="">Cookies</a>
            <a href="">Help</a>
            <a href="">FQAs</a> */}
          </div>
        </div>
        <div className="container copyright">
          <div className="row">
            <div className="col-md-6">
              <p>
                &copy; <a>Asm Designs</a>, All Right Reserved.
              </p>
            </div>

            {/* <div className="col-md-6">
              <p>
                Designed By
                <a href="https://freewebsitecode.com">Free Website Code</a>
              </p>
            </div> */}
          </div>
        </div>
      </div>

      <a href="#" className="back-to-top">
        <i className="fa fa-chevron-up"></i>
      </a>
    </div>
  );
}

export default Projects;
